// @ts-nocheck
import { Order } from "../interfaces/checkout/Order";
import { ElementsOrder } from "src/interfaces/checkout/ElementsOrder.ts";
import { SubElementsOrder } from "src/interfaces/checkout/ElementsOrder.ts";
import store from '../store/storeOrder/index'
import router from "@/router/index";
import Swal from 'sweetalert2';
import dayjs from 'dayjs';

export default class CheckoutUtils {

  /** Select default address on page checkout or select one when default address not existe */

  static preparCheckout(cart) {
    store.state.checkout.type = cart.deliveryMode;
    store.state.checkout.table = cart.table;
    const checkout = store.state?.checkout;
    let customer_store = null
    if (store.state?.SelectedCustomerStore) {
      customer_store = {
        "address": store.state?.SelectedCustomerStore?.address,
        "firstName": store.state?.SelectedCustomerStore?.firstName,
        "lastName": store.state?.SelectedCustomerStore?.lastName,
        "phone": store.state?.SelectedCustomerStore?.phone,
        "store_id": store.state?.SelectedCustomerStore?.store_id
      }
    }

    const preparedOrder: Order = {
      borne_cust_name: "Manager",
      order_by: "manager",
      type: cart.deliveryMode,
      is_paid: checkout?.isPaymentCard || false,
      comment: checkout?.comment,
      delivery_address_id: null,
      cac_address_id: checkout?.cac?.id || null,
      uuid: router.currentRoute.value.params.UserUUID, //cart?.uuid, // process.env.VUE_UUID,
      card_token: null, // checkout?.cardToken || null,
      at_place: cart.deliveryMode == "at_place" ? true : false,
      payment_at_checkout: checkout?.isPayementAtCheckout,
      code_number: store.state.storeData?.couponCode?.code_number || null,
      table_id: checkout?.table?.id || null,
      fees: cart.deliveryTotal || 0,
      planified: checkout?.planified.isPlanified,
      planified_at: checkout?.planified.planifiedAt,
      loyalty_percentage: 0, //store.state.storeData?.data?.config_loyalty[0]?.percentage || 0,
      loyalty_nb_orders: 0,//store.state.storeData?.data?.config_loyalty[0]?.nb_orders || 0,
      customer_store: customer_store || null,
      elementsOrder: cart.products.map((product, indexProduct: number) => {
        var subElementsOrder = [];
        var UnitePriceProduct = product.price;
        product.groups.map((group, indexGroup: number) => {
          if (group.used) {
            if (group.button_type == "CheckboxButton") {
              group.sub_products.map((subProduct, indexSubProduct: number) => {
                if (subProduct.checked) {
                  let UnitePriceSubProduct = subProduct.quantity > 0 ? (subProduct.price / subProduct.quantity) : subProduct.price
                  const preparesubElementsOrder: SubElementsOrder = {
                    type: group.type,
                    price: UnitePriceSubProduct,
                    quantity: subProduct.quantity,
                    sub_product_id: subProduct.id,
                  };
                  subElementsOrder.push(preparesubElementsOrder);
                }
              });
            } else if (
              group.button_type == "RadioButton" ||
              group.button_type == "PlusMinusButton"
            ) {
              group.sub_products.map((subProduct, indexSubProduct: number) => {
                if (subProduct.checked || subProduct.quantity) {
                  UnitePriceProduct = group.type == "size" ? subProduct.price : UnitePriceProduct;
                  let UnitePriceSubProduct = subProduct.quantity > 0 ? (subProduct.price / subProduct.quantity) : subProduct.price
                  const preparesubElementsOrder: SubElementsOrder = {
                    type: group.type,
                    price: UnitePriceSubProduct,
                    quantity: subProduct.quantity,
                    sub_product_id: subProduct.id,
                    promo: product.promos.find((item) => item["product_id"] === product.id && item["sub_product_id"] === subProduct.id),
                  };
                  subElementsOrder.push(preparesubElementsOrder);
                }
              });
            }
          }
        });
        const elementsOrder: ElementsOrder = {
          UnitPriceSize: UnitePriceProduct,
          price: product.price,
          quantity: product.quantity,
          comment: null,
          product_id: product.id,
          subElementsOrder: subElementsOrder,
          promo: product.promos.find((item) => item["product_id"] === product.id && item["sub_product_id"] == null)
        };
        return elementsOrder;
      }),
    };
    return preparedOrder;
  }

  static checkIsAllGoodToOrder(status) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true
    })
    let title = "";
    //console.log(store.state.storeData?.data?.option)
    if (!store.state.storeData?.data?.option?.is_open || !store.state.storeData?.data?.option?.is_store_open) {
      Toast.fire({
        icon: 'warning',
        title: 'Restaurant fermé'

      });
      store.state.storeData.isOpenToOrder = false;
      return false;
    }

    if (store.state.storeData?.data?.option) {
      if (
        (store.state.cartStore.deliveryMode == "liv" &&
          !store.state.storeData?.data?.option?.is_delivery_time) ||
        (store.state.cartStore.deliveryMode == "liv" &&
          !store.state.storeData?.data?.option?.order_delivery) ||
        (store.state.cartStore.deliveryMode == "cac" && !store.state.storeData?.data?.option?.is_cac_time) ||
        (store.state.cartStore.deliveryMode == "cac" && !store.state.storeData?.data?.option?.order_cac) ||
        (store.state.cartStore.deliveryMode == "at_place" &&
          !store.state.storeData?.data?.option?.is_at_place_time) ||
        (store.state.cartStore.deliveryMode == "at_place" &&
          !store.state.storeData?.data?.option?.order_at_place)
      ) {

        const isOrder = {
          liv: "Livraison non disponible",
          cac: "Emporter non disponible",
          at_place: "Sur place non disponible",
        }

        title = isOrder[store.state.cartStore.deliveryMode]
        Toast.fire({
          icon: 'warning',
          title: title

        });
        return false;
      }
    }

    if (store.state.storeData?.data?.feeses[0] && status == "order") {
      const fees = store.state.storeData?.data?.feeses[0];
      // const TotalAmount = store.state.cartStore?.subTotal + store.state.cartStore?.discountTotal;
      let TotalAmount = store.state.cartStore?.totalFinal - store.state.cartStore?.deliveryTotal
      if (fees[`min_price_${store.state.cartStore.deliveryMode}`] > TotalAmount) {
        title = store.state.cartStore.deliveryMode
        const minOrder = {
          liv: `La livraison est disponible à partir d'un minimum de commande de ${fees[`min_price_${store.state.cartStore.deliveryMode}`]} €`,
          cac: `Emporter est disponible à partir d'un minimum de commande de ${fees[`min_price_${store.state.cartStore.deliveryMode}`]} €`,
          at_place: `Sur place est disponible à partir d'un minimum de commande de ${fees[`min_price_${store.state.cartStore.deliveryMode}`]} €`
        }
        title = minOrder[store.state.cartStore.deliveryMode]
        Toast.fire({
          icon: 'warning',
          title: title

        });
        return false;
      }
    }

    return true;
  }

  static isRequiredToPlanified() {
    let next_opening_hours = store.state.storeData?.data?.opening_hours[store.state.cartStore.deliveryMode]?.nextOpening
    let nextOpening = next_opening_hours?.status != 'open' ? next_opening_hours : null;
    if (nextOpening?.openTime) {
      return true
    }
    return false;
  }


  static verifyFields() {
    let error = "";
    if (!store.state.SelectedCustomerStore) {
      error = "Selectionner ou crée un nouvelle client";
      return error;
    }
    if (store.state.SelectedCustomerStore && !store.state.SelectedCustomerStore?.isEnabled) {
      error = "Ce client est déjà sur la liste noire";
      return error;
    }
    let error = "";
    if (store.state.checkout.type == "liv") {
      if (store.state.checkout.liv == null || Object.keys(store.state.checkout.liv).length === 0) {
        error = "Choisir ou ajouter une adresse de livraison";
        return error;
      }
    }
    if (store.state.checkout.type == "cac") {
      if (store.state.checkout.cac == null || Object.keys(store.state.checkout.cac).length === 0) {
        error = "Choisir une adresse Emporter";
        return error;
      }
    }
    if (store.state.checkout.type == "at_place") {
      if (!store.state.checkout.table?.id) {
        error = "Table est obligatoire";
        return error;
      }
    }
    if (
      (store.state.checkout.type != "at_place" &&
        store.state.checkout.planified.planifiedAt == null &&
        store.state.checkout.planified.isRequired) ||
      (store.state.checkout.type != "at_place" &&
        store.state.checkout.planified.planifiedAt == null &&
        store.state.checkout.planified.isPlanified)
    ) {
      error = "Sélectionner le créneau de collecte qui vous convient";
      return error;
    }
    if (
      store.state.checkout.planified.planifiedAt == null &&
      store.state.checkout.planified.isPlanified
    ) {
      error = "Sélectionner une date valide";
      return error;
    }
    if (store.state.checkout.isPaymentCard) {
      if (store.state.checkout.cardToken == "") {
        error = "cardToken required";
        return error;
      }
    }
    return error;
  }

}
